export function toggleClass() {
  const toggle = document.querySelectorAll('.js-toggleClass');
  const toggleList = Array.prototype.slice.call(toggle, 0);

  /** is-active toggle */
  toggleList.forEach((targetBox) => {
    targetBox.addEventListener('click', () => {
      if (targetBox.classList.contains('is-active')) {
        targetBox.classList.remove('is-active');
      } else {
        targetBox.classList.add('is-active');
      }
    });
  });
}
