import { throttle } from 'lodash';

export function windowAction() {
  const bnrEle = document.querySelector('.js-bnr');
  const bnrClose = document.querySelector('.js-bnrClose');

  window.addEventListener(
    'scroll',
    _.throttle(
      (e) => {
        scrollBtnView();
        return false;
      },
      10,
      { trailing: true, leading: true }
    )
  );

  window.addEventListener(
    'resize',
    _.throttle(
      (e) => {
        headerReset();
        apparkTab();
        return false;
      },
      10,
      { trailing: true, leading: true }
    )
  );

  window.addEventListener('load', () => {
    scrollBtnView();
  });

  function headerReset() {
    const windowW = window.innerWidth;

    if (windowW > 767) {
      const menuEle = document.querySelectorAll('.js-menuTrigger');
      const menuEleList = Array.prototype.slice.call(menuEle, 0);
      const headerNavEle = document.querySelector('.js-headerNav');
      const bodyEle = document.querySelector('body');
      
      for (let i = 0; i < menuEleList.length; i++) {
        menuEleList[i].firstElementChild.classList.remove('is-active');
        menuEleList[i].lastElementChild.classList.remove('is-view');
      }
      bodyEle.classList.remove('is-fixed');

      if (document.querySelector('.js-hamburger')) {
        const hamburgerEle = document.querySelector('.js-hamburger');
        hamburgerEle.classList.remove('is-active');
        headerNavEle.classList.remove('is-open');
      }
    }
  }

  function scrollBtnView() {
    if (document.querySelector('.js-pageTopBtn')) {
      const scroll = window.pageYOffset;
      const pageTopBtn = document.querySelector('.js-pageTopBtn');

      if (scroll > 250) {
        pageTopBtn.classList.add('is-view');
      } else {
        pageTopBtn.classList.remove('is-view');
      }
    }
  }

  /** APサービス配下 apパークページのリサイズ時js修正パッチ（リニューアル前対応） */
  function apparkTab() {
    if (document.querySelector('.js-searchCont') && window.innerWidth > 767) {
      const searchCont = document.querySelectorAll('.js-searchCont');
      const searchContList = Array.prototype.slice.call(searchCont, 0);
      const searchTab = document.querySelectorAll('.js-searchTab');
      const searchTabList = Array.prototype.slice.call(searchTab, 0);

      searchTabList.forEach((targetBox) => {
        const tabDataType = targetBox.dataset.searchtab;

        if (targetBox.classList.contains('search__view__item--current')) {
          if (tabDataType == 'map') {
            for (let i = 0; i < searchContList.length; i++) {
              searchContList[i].classList.remove('search--list');
              searchContList[i].classList.add('search--map');
            }
          }
          if (tabDataType == 'list') {
            for (let i = 0; i < searchContList.length; i++) {
              searchContList[i].classList.remove('search--map');
              searchContList[i].classList.add('search--list');
            }
          }
        }
      });
    }
  }
  /** end APサービス配下 apパークページのリサイズ時js修正パッチ（リニューアル前対応） */
}
