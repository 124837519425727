import { smooth } from './modules/smooth';
import { toggleClass } from './modules/toggleClass';
import { headerAction } from './modules/header';
import { windowAction } from './modules/windowAction';
import { bnrControl } from './modules/bnrControl';

window.addEventListener('DOMContentLoaded', () => {
  bnrControl();
});

smooth();
toggleClass();
headerAction();
windowAction();

var ua = navigator.userAgent.toLowerCase();
var isiOS = ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1;
if (isiOS) {
  const viewport = document.querySelector('meta[name="viewport"]');
  if (viewport) {
    var viewportContent = viewport.getAttribute('content');
    viewport.setAttribute('content', viewportContent + ', user-scalable=no');
  }
}
