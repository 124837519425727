export function modalItem() {
  if (document.querySelector('.js-modalItemTrigger')) {
    const modalItemTriggerAll = document.querySelectorAll('.js-modalItemTrigger');
    const modalItemAll = document.querySelectorAll('.js-modalItem');
    const IS_VIEW = 'is-view';

    modalItemTriggerAll.forEach((target) => {
      target.addEventListener('click', () => {
        const thisTargetData = target.dataset.item;

        for (let modalItemIndex = 0; modalItemIndex < modalItemAll.length; modalItemIndex++) {
          const modalItemData = modalItemAll[modalItemIndex].dataset.item;

          if (modalItemData == thisTargetData) {
            modalItemAll[modalItemIndex].classList.add(IS_VIEW);
          }
        }
      });
    });

    modalItemAll.forEach((target) => {
      const thisModalItemClose = target.querySelector('.js-modalItemClose');
      const thisModalItemBg = target.querySelector('.js-modalItemBg');

      thisModalItemClose.addEventListener('click', () => {
        target.classList.remove(IS_VIEW);
      });

      thisModalItemBg.addEventListener('click', () => {
        target.classList.remove(IS_VIEW);
      });
    });
  }
}
