export function headerAction() {
  const menuEle = document.querySelectorAll('.js-menuTrigger');
  const menuEleList = Array.prototype.slice.call(menuEle, 0);
  const menuInnerEle = document.querySelectorAll('.js-menuInnerTrigger');
  const menuInnerEleList = Array.prototype.slice.call(menuInnerEle, 0);
  const headerNavEle = document.querySelector('.js-headerNav');

  /** メガメニュー展開用 */
  menuEleList.forEach((targetBox) => {
    targetBox.addEventListener('mouseover', () => {
      if (document.querySelector('.js-hamburger')) {
        const hamburgerEle = document.querySelector('.js-hamburger');

        if (!hamburgerEle.classList.contains('is-active')) {
          targetBox.firstElementChild.classList.add('is-active');
          targetBox.lastElementChild.classList.add('is-view');
        }
      }
    });

    //

    targetBox.addEventListener('mouseout', () => {
      if (document.querySelector('.js-hamburger')) {
        const hamburgerEle = document.querySelector('.js-hamburger');
        if (!hamburgerEle.classList.contains('is-active')) {
          targetBox.firstElementChild.classList.remove('is-active');
          targetBox.lastElementChild.classList.remove('is-view');
        }
      }
    });
  });

  menuInnerEleList.forEach((targetBox) => {
    targetBox.addEventListener('click', () => {
      if (document.querySelector('.js-hamburger')) {
        const hamburgerEle = document.querySelector('.js-hamburger');
        if (hamburgerEle.classList.contains('is-active')) {
          if (targetBox.classList.contains('is-active')) {
            targetBox.classList.remove('is-active');
            targetBox.nextElementSibling.classList.remove('is-view');
          } else {
            for (let i = 0; i < menuInnerEleList.length; i++) {
              menuInnerEleList[i].classList.remove('is-active');
              menuInnerEleList[i].nextElementSibling.classList.remove('is-view');
            }

            targetBox.classList.add('is-active');
            targetBox.nextElementSibling.classList.add('is-view');
          }
        }
      }
    });
  });
  /** end メガメニュー展開用 */

  /** ハンバーガーメニュー用 */
  if (document.querySelector('.js-hamburger')) {
    const hamburgerEle = document.querySelector('.js-hamburger');
    hamburgerEle.addEventListener('click', () => {
      const bodyEle = document.querySelector('body');

      if (hamburgerEle.classList.contains('is-active')) {
        bodyEle.classList.remove('is-fixed');
        hamburgerEle.classList.remove('is-active');
        headerNavEle.classList.remove('is-open');
      } else {
        bodyEle.classList.add('is-fixed');
        hamburgerEle.classList.add('is-active');
        headerNavEle.classList.add('is-open');
      }
    });
  }
  /** end ハンバーガーメニュー用 */
}
