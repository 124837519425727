export function bnrControl() {
  if (document.getElementById('input_form')) {
    const windowW = window.innerWidth;

    if (document.querySelector('.c-fixBnr')) {
      if (windowW < 768) {
        const fixBnrEle = document.querySelector('.c-fixBnr');
        fixBnrEle.style.display = 'none';
      }
    }
  }
}
